<template>
    <div id="agreementPage">
      <div v-html="message"></div>
    </div>
</template>

<script>
export default {
  name: 'Agreement',
  components: {},
  data () {
    return {
      type: '',
      message: ''
    };
  },
  created () {
  },
  mounted () {
    const { params: { type } } = this.$route;
    this.type = type * 1;
    this.init();
  },
  watch: {},
  methods: {
    init () {
      const { type } = this;
      let title = '';
      switch (type) {
        case 1:
          title = 'プライバシーポリシー';
          this.$route.meta.title = title;
          this.getMessage(type);
          break;
        case 2:
          title = '利用規約';
          this.$route.meta.title = title;
          this.getMessage(type);
          break;
        case 3:
          title = 'サブスクリプション規約';
          this.$route.meta.title = title;
          this.getMessage(type);
          break;
      }
    },
    getMessage (type) {
      this.$http.post('set/appInformation', {
        type: type
      }).then((res) => {
        let data = res.data || {};
        this.message = data.content;
      });
    }
  }
};
</script>

<style scoped lang="less">
  #agreementPage {

  }
</style>